<template>
  <div class="home_mobile">
    <HeaderVue></HeaderVue>
    <router-view></router-view>
    <FooterVue></FooterVue>
  </div>
</template>

<script>
import HeaderVue from '../components/Header.vue'
import FooterVue from '../components/Footer.vue'
export default {
  name: 'Home_Mobile',
  components: {
    HeaderVue,
    FooterVue
  },
}
</script>

<style lang="less" scoped>
#home {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
