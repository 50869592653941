<template>
  <div>
    <div class="page-footer" v-if="!isSmallScreen">
      <div class="footer-top">
        <div>
          <h6>Services</h6>
          <ul>
<!--            <li><a :href="$lingomate" target="_blank">Lingo Mate</a></li>-->
            <li><a :href="$dolphinsoe" target="_blank">Smart Oral Evaluation</a></li>
            <li><a :href="$dolphinvoice" target="_blank">Dolphin AI Cloud Platform</a></li>
          </ul>
        </div>
        <div>
          <h6>Company</h6>
          <ul>
            <li @click="openPage('/company?id=Weare')">Introduction</li>
            <li @click="openPage('/company?id=Profile')">Profile</li>
            <li @click="openPage('/company?id=Founder')">Founder</li>
          </ul>
        </div>
        <div>
          <h6 @click="openPage('/contact')">Contact</h6>
        </div>
        <div>
          <p><img src="~@/assets/images/logo_footer.png" alt=""></p>
          <p><strong @click="openMD('利用規約')">利用規約</strong></p>
          <p><strong @click="openMD('プライバシー')">プライバシー</strong></p>
        </div>
      </div>
      <div class="footer-bot page-container">
        <span>© 2024 Dolphin AI Inc.</span>
        <span> All rights reserved.</span>
      </div>
    </div>

    <div class="mobile_page-footer" v-if="isSmallScreen">
      <div class="mobile_footer-top">
        <div>
          <h6>Services</h6>
          <ul>
<!--            <li><a :href="$lingomate" target="_blank">Lingo Mate</a></li>-->
            <li><a :href="$dolphinsoe" target="_blank">Smart Oral Evaluation</a></li>
            <li><a :href="$dolphinvoice" target="_blank">Cloud Platform</a></li>
          </ul>
        </div>
        <div>
          <h6>Company</h6>
          <ul>
            <li @click="openPage('/company?id=Weare')">Introduction</li>
            <li @click="openPage('/company?id=Profile')">Profile</li>
            <li @click="openPage('/company?id=Founder')">Founder</li>
          </ul>
        </div>
        <div>
          <h6 @click="openPage('/contact')">Contact</h6>
        </div>
        <div>
          <p><img src="~@/assets/images/logo_footer_m.png" alt=""></p>
          <p><strong @click="openMD('利用規約')">利用規約</strong></p>
          <p><strong @click="openMD('プライバシー')">プライバシー</strong></p>
        </div>
      </div>
      <div class="mobile_footer-bot mobile_page-container">
        <span >© 2024 Dolphin AI Inc.</span>
        <span > All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-footer',
  data () {
    return {
      isSmallScreen: window.innerWidth <= 768 // 根据需要设置屏幕宽度阈值
    }
  },
  methods: {
    goWaibu(val) {
      window.open(val,'_blank')
    },
    openPage (url) {
      this.$router.push(url)
    },
    openMD (url) {
      this.$router.push({
        path: '/file',
        query: {
          name: url
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-footer {
  width: 1200px;
  height: 309px;
  margin: 0 auto;
  background: #fff;
}
.footer-top {
  height: 260px;
  display: flex;
  >div {
    flex: 1;
    padding-top: 48px;
    border-right: 1px solid rgba(238, 238, 238, 1);
    h6 {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.14px;
      line-height: 24px;
      color: rgba(32, 34, 37, 1);
      text-align: left;
      vertical-align: top;
      margin-bottom: 32px;
      cursor: pointer;
      cursor: hand;
    }
    ul >li, ul >li a, p >strong {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.14px;
      line-height: 24px;
      color: rgba(120, 127, 132, 1);
      text-align: left;
      vertical-align: top;
      margin-bottom: 20px;
      cursor: pointer;
      cursor: hand;
    }
    p >span {
      font-size: 22px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 32px;
      color: rgba(32, 34, 37, 1);
      text-align: center;
      vertical-align: top;
    }
    p >img {
      width: 157px;
      height: 40px;
    }
    >p:nth-of-type(1) {
      margin-bottom: 20px;
      height: 32px;
      align-items: center;
      display: flex;
    }
    >p:nth-of-type(2) {
      margin-bottom: 10px;
    }
  }
  >div:nth-of-type(4) {
    border-right: none;
  }
  >div:nth-of-type(2), >div:nth-of-type(3), >div:nth-of-type(4) {
    padding-left: 64px;
  }
}
.footer-bot {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(238, 238, 238, 1);
  span {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.16px;
    line-height: 21.33px;
    color: rgba(120, 127, 132, 1);
    text-align: left;
    vertical-align: top;
  }
}

//=======================移动端============================================
.mobile_page-footer {
  height: 155px;
  background: #fff;
}
.mobile_footer-top {
  display: flex;
  >div {
    padding-top: 20px;
    border-right: 1px solid rgba(238, 238, 238, 1);
    h6 {
      font-size: 10px;
      font-weight: 600;
      letter-spacing: -0.14px;
      line-height: 24px;
      color: rgba(32, 34, 37, 1);
      text-align: left;
      vertical-align: top;
      margin-bottom: 8px;
      cursor: pointer;
      cursor: hand;
    }
    ul {
      margin-right: 5px;
    }
    ul >li, ul >li a, p >strong {
      width: auto;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: -0.14px;
      line-height: 14px;
      color: rgba(120, 127, 132, 1);
      text-align: left;
      vertical-align: top;
      margin-bottom: 4px;
      cursor: pointer;
      cursor: hand;
      outline: none;
    }
    p >span {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.22px;
      line-height: 32px;
      color: rgba(32, 34, 37, 1);
      text-align: center;
      vertical-align: top;
    }
    p >img {
      width: 70px;
      height: 18px;
    }
    >p:nth-of-type(1) {
      height: 24px;
      align-items: center;
      display: flex;
      margin-bottom:10px;
    }
  }
  >div:nth-of-type(4) {
    border-right: none;
  }
  >div:nth-of-type(1) {
    padding-left: 14px;
    padding-bottom:15px;
  }
  >div:nth-of-type(2) {
    padding-left: 14px;
    width:60px;
  }
  >div:nth-of-type(3){
    padding-left: 14px;
    width:60px;
  }
  >div:nth-of-type(4) {
    padding-left: 14px;
    width:106px;
  }
}
.mobile_footer-bot {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(238, 238, 238, 1);
  padding-left: 60px;
  padding-right:60px;
  span {
    display: flex;
    white-space: nowrap;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: -0.12px;
    line-height: 16px;
    color: rgba(120, 127, 132, 1);
    text-align: left;
  }
}
</style>
