<template>
  <div>
    <div id="head-page" class="head-container page-container" v-if="!isSmallScreen">
      <div class="logo" @click="$router.push('/')">
        <img src="~@/assets/images/logo.png">
      </div>
      <el-menu :default-active="activeIndex" router class="el-menu" mode="horizontal">
        <el-submenu index="/">
          <template slot="title">Services</template>
<!--          <el-menu-item><a :href="$lingomate" target="_blank">Lingo Mate</a></el-menu-item>-->
          <el-menu-item><a :href="$dolphinsoe" target="_blank">Smart Oral Evaluation</a></el-menu-item>
          <el-menu-item><a :href="$dolphinvoice" target="_blank">Dolphin AI Cloud Platform</a></el-menu-item>
        </el-submenu>
        <el-submenu index="/company">
          <template slot="title">Company</template>
          <el-menu-item index="/company?id=Weare">Introduction</el-menu-item>
          <el-menu-item index="/company?id=Profile">Profile</el-menu-item>
          <el-menu-item index="/company?id=Founder">Founder</el-menu-item>
        </el-submenu>
        <el-menu-item index="/contact">Contact</el-menu-item>
      </el-menu>
    </div>
    <div id="mobile_head-page" class="mobile_head-container mobile_page-container" v-if="isSmallScreen">
      <div class="mobile_logo" @click="$router.push('/')">
        <img src="~@/assets/images/logo.png">
      </div>
      <el-menu :default-active="activeIndex" router class="mobile_el-menu" mode="horizontal">
        <el-submenu index="/">
          <template slot="title">Services</template>
<!--          <el-menu-item><a :href="$lingomate" target="_blank">Lingo Mate</a></el-menu-item>-->
          <el-menu-item><a :href="$dolphinsoe" target="_blank">Smart Oral Evaluation</a></el-menu-item>
          <el-menu-item><a :href="$dolphinvoice" target="_blank">Dolphin AI Cloud Platform</a></el-menu-item>
        </el-submenu>
        <el-submenu index="/company">
          <template slot="title">Company</template>
          <el-menu-item index="/company?id=Weare">Introduction</el-menu-item>
          <el-menu-item index="/company?id=Profile">Profile</el-menu-item>
          <el-menu-item index="/company?id=Founder">Founder</el-menu-item>
        </el-submenu>
        <el-menu-item index="/contact">Contact</el-menu-item>
      </el-menu>
    </div>
  </div>

</template>

<script>
export default {
  name: 'head-page',
  data () {
    return {
      activeIndex: this.$route.path,
      isSmallScreen: window.innerWidth <= 768 // 根据需要设置屏幕宽度阈值
    }
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path
      this.showType = 1
    }
  },
  methods: {
    goWaibu(val) {
      window.open(val,'_blank')
    }
  }
}
</script>

<style lang="less" scoped>
#head-page {
  height: 60px;
}
.head-container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(225deg, rgba(87, 120, 255, 1) 0%, rgba(47, 41, 223, 1) 100%);
  position: relative;
  z-index: 2;
}
.logo {
  height: 40px;
  cursor: pointer;
  cursor: hand;
  img {
    height: 40px;
  }
}
.el-menu {
  height: 60px;
  background-color: transparent;
  border-bottom: none;
  >li, >.el-submenu /deep/ .el-submenu__title {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 0;
    text-align: center;
  }
  >li {
    width: 160px;
  }
  >.el-submenu /deep/ .el-submenu__title, .el-menu-item {
    color: #fff;
  }
  >.el-submenu /deep/ .el-submenu__title:hover, >.el-menu-item:hover {
    background-color: transparent;
    color: #fff;
    font-weight: 700;
  }
  >.el-submenu /deep/ .el-submenu__icon-arrow {
    display: none;
  }
}

//=======================移动端======================
#mobile_head-page {
  height: 45px;
}
.mobile_head-container {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(225deg, rgba(87, 120, 255, 1) 0%, rgba(47, 41, 223, 1) 100%);
  position: relative;
  z-index: 2;
}
.mobile_logo {
  height: 24px;
  cursor: pointer;
  cursor: hand;
  img {
    height: 24px;
  }
}
.mobile_el-menu {
  height: 45px;
  background-color: transparent;
  border-bottom: none;
  >li, >.el-submenu /deep/ .el-submenu__title {
    height: 45px;
    line-height: 45px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 0;
    text-align: center;
  }
  >li {
    width: 70px;
  }
  >.el-submenu /deep/ .el-submenu__title, .el-menu-item {
    color: #fff;
  }
  >.el-submenu /deep/ .el-submenu__title:hover, >.el-menu-item:hover {
    background-color: transparent;
    color: #fff;
    font-weight: 700;
  }
  >.el-submenu /deep/ .el-submenu__icon-arrow {
    display: none;
  }
}
</style>
